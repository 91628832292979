<template>
  <div class="accountWrap">
    <div class="facilityBox">
      <div class="topbtns">
        <el-button icon="el-icon-download" type="primary" size="small" :disabled="upData.length == 0 ? true : false"
          @click="bindFinal('account')">下载结算单</el-button>
        <el-button :icon="addIcon" type="primary" size="small" :disabled="upData.length == 0 ? true : false"
          @click="bindFinal('contract')">补签合同</el-button>
      </div>
      <!-- form表单 -->
      <el-form class="manageForm" :model="params" :inline="true">
        <!-- <el-form-item prop="EnterpriseFullName">
          <el-input placeholder="请输入内容" v-model="params.EnterpriseFullName" @keyup.enter.native="search()">
            <el-select v-model="params.currentEnterprise" slot="prepend">
              <el-option label="货主及服务商单位" value="1"></el-option>
              <el-option label="货主单位" value="2"></el-option>
              <el-option label="服务商单位" value="3"></el-option>
            </el-select> 
          </el-input>
        </el-form-item> -->
        <el-form-item label="货主单位：" prop="DemanderName">
          <el-input class="fromInp" v-model="params.DemanderName" placeholder="输入货主单位" @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="服务商单位：" prop="SupplierName">
          <el-input class="fromInp" v-model="params.SupplierName" placeholder="输入服务商单位" @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="开票申请编号：" prop="InvoiceTaskID">
          <el-input class="fromInp" v-model="params.InvoiceTaskID" placeholder="输入开票申请编号" @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="发票类型：" prop="InvoiceType">
          <el-select v-model="params.InvoiceType" clearable placeholder="请选择发票类型" @change="search()">
            <el-option v-for="item in options" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算状态：" prop="IsChargeback">
          <el-select v-model="params.IsChargeback" clearable placeholder="请选择结算状态">
            <el-option label="全部" value=""></el-option>
            <el-option label="退款" value="1"></el-option>
            <el-option label="正常" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开票日期：" prop="date">
          <el-date-picker v-model="params.TaskDatetime" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"
            value-format="yyyy-MM-dd" @change="searchChange()">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
          <el-button icon="el-icon-download" :disabled="!tableData.length" type="primary" size="medium"
            @click="exportExcel" :loading="loading">导出表格
          </el-button>
        </el-form-item>
      </el-form>
      <!-- <div class="settleBox">
        <div style="display:flex;justify-content: space-between;">
          <div class="sum-item"><span class="sum-txt">历史总开票(张)：</span><span class="sum-num">{{
            invoices.InvoiceCount ? invoices.InvoiceCount : '/'
          }}</span></div>
          <div class="sum-item">
            <span class="sum-txt">历史运单数量(条)：</span><span class="sum-num">{{
              invoices.WaybillCount ? invoices.WaybillCount : '/'
            }}</span>
          </div>
          <div class="sum-item"><span class="sum-txt">历史总运费(元)：</span><span class="sum-num">{{
            invoiceTotals.TotalCarriageAmount
          }}</span></div>
          <div class="sum-item"><span class="sum-txt">历史总税费(元)：</span><span class="sum-num">{{
           invoiceTotals.TotalTaxAmount
          }}</span></div>
          <div class="sum-item"><span class="sum-txt">历史总支付金额(元)：</span><span class="sum-num">{{
            invoiceTotals.TotalPayTotalAmount
          }}</span></div>
        </div>
      </div> -->
      <el-table :data="tableData" @select="selectChange" @select-all="selectChange"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading">
        <el-table-column type="selection" align="center" width="55" fixed="left" show-overflow-tooltip>
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50" fixed="left" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" width="180" fixed="left"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ApplicantUserName" label="货主单位" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PrincipalCompanyName" label="服务商单位" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskDatetime" label="开票完成时间" width="140" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceNumber" label="总计运单数量" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="费率" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="运费" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.TransportCosts }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="NeedPayMoney" label="运单总金额" width="110" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.NeedPayMoney | formatMoney }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="BackTransportCosts" label="退单运费" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.BackTransportCosts }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="BackExpensesOfTaxation" label="退单服务费/税费" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              <span v-if="scope.row.BackExpensesOfTaxation != '/'">{{ scope.row.BackExpensesOfTaxation | formatMoney
              }}</span>
              <span v-else>{{ scope.row.BackExpensesOfTaxation }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="BackNeedPayMoney" label="退单总金额" width="110" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.BackNeedPayMoney | formatMoney }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="BackRealAmount" label="实际总金额" width="110" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.BackRealAmount | formatMoney }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="goFacility(scope.row)" icon="el-icon-tickets">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="凭证管理(线下付款)" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="openVoucherDialog(scope.row)" icon="el-icon-picture"
              :disabled="!scope.row.PayTaskID">凭证管理
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <!-- 上传凭证dialog -->
    <el-dialog width="1200px" title="凭证" center :visible.sync="flag.voucherDialog" append-to-body
      v-if="flag.voucherDialog" :close-on-click-modal="false">
      <EvidenceInfo :businessID="currentItem.InvoiceTaskID">
      </EvidenceInfo>
    </el-dialog>
  </div>
</template>

<script>
import {
  tasksInfoList,
  taskSummary,
  upExport,
  reissueContract,
  DownLoadIvoiceTasksInfoList
} from "@/api/finance/account/index";
import { getDataDict } from "@/api/common/common.js";
import { _downloadFile } from "@/utils/utils";
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo";
export default {
  name: 'platformAccount',
  data() {
    return {
      // 发票类型数据
      options: [
        {
          Code: '',
          Name: '全部'
        }
      ],
      // 是否显示弹窗
      flag: {
        voucherDialog: false, //凭证dialog
      },
      // 选择开票日期
      params: {
        TaskDatetime: ['', ''],
        // EnterpriseFullName: '',
        DemanderName: '',
        SupplierName: '',
        InvoiceTaskID: "",
        // currentEnterprise: '1',
        InvoiceType: '',
        IsChargeback: ''
      },
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [],
      // 平台-所有服务商历史结算单汇总
      invoices: "",
      // 下载结算单列表
      upData: [],
      loading: true,
      addIcon: 'el-icon-document-add',
      currentItem: {},
      invoiceTotals:{
        TotalCarriageAmount:'',
        TotalTaxAmount:'',
        TotalPayTotalAmount:''
      }
    };
  },
  created() {
    this.pagination.page = this.$route.query.pageIndex ? Number(this.$route.query.pageIndex) : 1
    this.params.TaskDatetime[0] = this.$route.query.StartDatetime ? this.$route.query.StartDatetime : ''
    this.params.TaskDatetime[1] = this.$route.query.EndDatetime ? this.$route.query.EndDatetime : ''
    // this.params.currentEnterprise = this.$route.query.currentEnterprise ? this.$route.query.currentEnterprise : '1'
    // this.params.EnterpriseFullName = this.$route.query.EnterpriseFullName ? this.$route.query.EnterpriseFullName : ''
    this.params.DemanderName = this.$route.query.DemanderName ? this.$route.query.DemanderName : ''
    this.params.SupplierName = this.$route.query.SupplierName ? this.$route.query.SupplierName : ''
    this.params.InvoiceTaskID = this.$route.query.InvoiceTaskID ? this.$route.query.InvoiceTaskID : ''
    this.params.InvoiceType = this.$route.query.InvoiceType || ''
    // 获取结算信息列表
    this.tasksInfoList();
    // 汇总开票相关信息
    // this.taskSummary();
    // 获取发票类型
    getDataDict({ type: 20 }).then((res) => {
      this.options = this.options.concat(res.patterSetInfo)
    });
  },
  // mounted() {
  //   // 监听浏览器刷新
  //   window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  // },
  // destroyed() {
  //   // 移除浏览器刷新
  //   window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  // },
  methods: {
    // 回车搜索
    searchChange() {
      this.search()
    },
    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == '/finance/account/index') {
        // 重置路由
        this.$router.push({
          path: "/finance/account/index"
        });
      }
    },
    // 下载结算单列表
    bindFinal(type) {
      let data = {
        InvoiceTaskIDs: [],
      };
      if (type == 'contract') {
        this.loading = true
        this.addIcon = 'el-icon-loading';
        this.upData.forEach((item) => {
          let obj = {
            InvoiceTaskID: item.InvoiceTaskID,
          };
          data.InvoiceTaskIDs.push(obj);
        });
        reissueContract({ Json: JSON.stringify(data) }).then(res => { }).finally(() => {
          this.addIcon = 'el-icon-document-add'
          this.loading = false
        });
      } else {
        data.fileType = "结算单"
        data.fileName = "平台结算单"
        data.Title = []
        this.upData.forEach((item) => {
          let obj = {
            InvoiceTaskID: item.InvoiceTaskID,
          };
          data.InvoiceTaskIDs.push(obj);
        });
        upExport({ Json: JSON.stringify(data) }).then((res) => {
          for (let i in res.pathList) {
            _downloadFile(res.pathList[i].pathName);
            this.upData = [];
            this.tasksInfoList();
          }
        });
      }
    },
    // 下载结算单
    selectChange(val) {
      this.upData = val;
    },
    // 搜索
    search() {
      this.pagination.page = 1;
      this.tasksInfoList();
    },
    // 清空搜索
    resetForm() {
      this.pagination.page = 1;
      this.params = {
        TaskDatetime: ['', ''],
        // EnterpriseFullName: '',
        DemanderName: '',
        SupplierName: '',
        InvoiceTaskID: "",
        // currentEnterprise: '1',
        InvoiceType: '',
        IsChargeback: ''
      }
      this.tasksInfoList();
    },
    // 汇总开票相关信息
    taskSummary() {
      let data = {
        // InvoiceNumber: '',
        // StartDatetime: '',
        // InvoiceType: '',
        // EnterpriseFullName: '',
        // RoleType: 2
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        StartDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[0] : '',
        EndDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[1] : '',
        // EnterpriseFullName: this.params.currentEnterprise == 1 ? this.params.EnterpriseFullName : '',
        InvoiceTaskID: this.params.InvoiceTaskID ? this.params.InvoiceTaskID : "",
        DemanderName: this.params.DemanderName,
        SupplierName: this.params.SupplierName,
        // DemanderName: this.params.currentEnterprise == 2 ? this.params.EnterpriseFullName : '',
        // SupplierName: this.params.currentEnterprise == 3 ? this.params.EnterpriseFullName : '',
        RoleType: 1,
        InvoiceType: this.params.InvoiceType,
        IsChargeback: this.params.IsChargeback
      }
      taskSummary({ Json: JSON.stringify(data) }).then((res) => {
        this.invoices = res.data;
      });
    },
    // 获取结算信息列表
    tasksInfoList() {
      this.loading = true
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        StartDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[0] : '',
        EndDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[1] : '',
        // EnterpriseFullName: this.params.currentEnterprise == 1 ? this.params.EnterpriseFullName : '',
        InvoiceTaskID: this.params.InvoiceTaskID ? this.params.InvoiceTaskID : "",
        DemanderName: this.params.DemanderName,
        SupplierName: this.params.SupplierName,
        // DemanderName: this.params.currentEnterprise == 2 ? this.params.EnterpriseFullName : '',
        // SupplierName: this.params.currentEnterprise == 3 ? this.params.EnterpriseFullName : '',
        RoleType: 1,
        InvoiceType: this.params.InvoiceType,
        IsChargeback: this.params.IsChargeback
      };
      tasksInfoList({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.invoices;
        this.pagination.total = Number(res.totalRowCount);
        this.invoiceTotals.TotalCarriageAmount=res.totals[0].TotalTransportCosts==0.00?'/':"￥"+res.totals[0].TotalTransportCosts ;
          this.invoiceTotals.TotalTaxAmount=res.totals[0].TotalExpensesOfTaxation==0.00?'/':"￥"+ res.totals[0].TotalExpensesOfTaxation;
          this.invoiceTotals.TotalPayTotalAmount=res.totals[0].TotalNeedPayMoney==0.00?'/':"￥"+res.totals[0].TotalNeedPayMoney ;
        // this.taskSummary()
      }).finally(() => {
        this.loading = false;
      });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e
      this.tasksInfoList()
    },
    //查看结算单详情
    goFacility(item) {
      this.$router.push({
        path: "/finance/account/accountInfo",
        query: {
          InvoiceTaskID: item.InvoiceTaskID,
          pageIndex: this.pagination.page,
          StartDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[0] : '',
          EndDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[1] : '',
          // currentEnterprise: this.params.currentEnterprise,
          // EnterpriseFullName: this.params.EnterpriseFullName ? this.params.EnterpriseFullName : '',
          DemanderName: this.params.DemanderName ? this.params.DemanderName : '',
          SupplierName: this.params.SupplierName ? this.params.SupplierName : '',
          InvoiceType: this.params.InvoiceType
        },
      });
    },
    //打开上传凭证弹窗
    openVoucherDialog(item) {
      this.currentItem = item
      this.flag.voucherDialog = true
    },
    //导出excel
    exportExcel() {
      this.loading = true
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        StartDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[0] : '',
        EndDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[1] : '',
        InvoiceTaskID: this.params.InvoiceTaskID ? this.params.InvoiceTaskID : "",
        DemanderName: this.params.DemanderName,
        SupplierName: this.params.SupplierName,
        RoleType: 1,
        InvoiceType: this.params.InvoiceType,
        IsChargeback: this.params.IsChargeback,
        DwonType: 1 // 下载类型：1-结算，2-资金
      };
      DownLoadIvoiceTasksInfoList({ Json: JSON.stringify(data) }).then((res) => {
        window.location.href = res.data
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  components: {
    EvidenceInfo
  },
};
</script>

<style lang="scss">
.accountWrap .el-select .el-input {
  width: 130px;
}
</style>

<style lang="scss" scoped>
.accountWrap {
  min-width: 1070px;
  box-sizing: border-box;

  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .settleBox {
    margin-bottom: 10px;
    padding: 10px 16px;
    background-color: #f0f0f0;
    color: #666;
  }

  .sum-item {
    color: rgb(255, 139, 23);
    margin-right: 15px;
    white-space: nowrap;

    .sum-txt {
      color: #333;
      font-weight: bold;
      text-align: right;
      width: 120px;
      display: inline-block;
    }

    .sum-num {
      display: inline-block;
      width: 200px;
    }
  }

  .accountTitle {
    margin: 20px 0 0 0;
    font-size: 24px;
    text-align: center;

    .accountInfo {
      width: 30vw;
      background: #f2f2f2;
      padding: 10px;
      border-radius: 10px;
      margin: 20px 0 0 0;
      font-size: 16px;
      line-height: 30px;
    }
  }
}

.settleBox {
  line-height: 32px;
  padding: 0px 16px;

  div {
    div {
      span {
        vertical-align: top;
        display: inline-block;
        margin: -1px 0 0 0;
      }
    }
  }
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>